import React, { Component } from "react";

import { Route, Switch, Redirect } from "react-router-dom";

import AppClock from "../components/Clock";
import AppTopbar from "../components/Topbar";
import AppSidebar from "../components/Sidebar";
import AppBreadCrumb from "../components/BreadCrumb";

import * as Constants from "../constants/constants";

import {
	AppPage404,
	AppPage401,
	AppPageError,
	AppPageLogin,
} from "../components/Pages";

import AppRouter from "./AppRouter";

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			visibleMobileSidebarMenu: false,
			visibleMobileTopbarMenu: false,
			loggedIn: false,
			userName: "",
			userAka: "",
			userRole: "",
		};

		this.toggleMobileSidebarMenu = this.toggleMobileSidebarMenu.bind(this);
		this.hideMobileSidebarMenu = this.hideMobileSidebarMenu.bind(this);
		this.toggleMobileTopbarMenu = this.toggleMobileTopbarMenu.bind(this);
		this.hideMobileTopbarMenu = this.hideMobileTopbarMenu.bind(this);
	}

	componentDidMount() {
		if (localStorage.getItem(Constants.STORAGE_JWT) !== null) {
			this.setState({
				loggedIn: true,
				userName: localStorage.getItem(Constants.STORAGE_USER_NAME),
				userAka: localStorage.getItem(Constants.STORAGE_USER_AKA),
				userRole: localStorage.getItem(Constants.STORAGE_USER_ROLE),
			});
		}
	}

	toggleMobileSidebarMenu() {
		if (this.state.visibleMobileSidebarMenu === false) {
			this.setState({ visibleMobileSidebarMenu: true });
		} else {
			this.setState({ visibleMobileSidebarMenu: false });
		}
	}

	hideMobileSidebarMenu() {
		if (this.state.visibleMobileSidebarMenu === true) {
			this.setState({ visibleMobileSidebarMenu: false });
		}
	}

	toggleMobileTopbarMenu() {
		if (this.state.visibleMobileTopbarMenu === false) {
			this.setState({ visibleMobileTopbarMenu: true });
		} else {
			this.setState({ visibleMobileTopbarMenu: false });
		}
	}

	hideMobileTopbarMenu() {
		if (this.state.visibleMobileTopbarMenu === true) {
			this.setState({ visibleMobileTopbarMenu: false });
		}
	}

	render() {
		if (localStorage.getItem(Constants.STORAGE_JWT) === null) {
			return (
				<React.Fragment>
					<Redirect to="/login" />
					<Route exact path="/login" component={AppPageLogin} />
				</React.Fragment>
			);
		} else {
			let date = new Date();
			let nowTime = date.getTime();
			let jwtExpiration = parseInt(
				localStorage.getItem(Constants.STORAGE_JWT_EXPIRATION)
			);
			if (nowTime >= jwtExpiration * 1000) {
				localStorage.clear();
				return (
					<React.Fragment>
						<Redirect to="/login" />
						<Route exact path="/login" component={AppPageLogin} />
					</React.Fragment>
				);
			}
		}

		return (
			<Switch>
				<Route exact path="/login">
					<Redirect to="/dashboard" />
				</Route>
				<Route exact path="/404" component={AppPage404} />
				<Route exact path="/401" component={AppPage401} />
				<Route exact path="/error" component={AppPageError} />
				<Route
					path="*"
					render={(props) => {
						return (
							<div
								className={`layout-wrapper layout-menu-light ${
									this.state.visibleMobileSidebarMenu
										? "layout-sidebar-mobile-active"
										: ""
								} ${
									this.state.visibleMobileTopbarMenu
										? "layout-topbar-mobile-active"
										: ""
								}`}
							>
								<div className="layout-sidebar">
									<AppSidebar mobileHandler={this.hideMobileSidebarMenu} />
								</div>

								<div className="layout-main">
									<AppTopbar
										mobileSidebarHandler={this.toggleMobileSidebarMenu}
										mobileTopbarHandler={this.toggleMobileTopbarMenu}
										userName={this.state.userName}
										userAka={this.state.userAka}
										userRole={this.state.userRole}
									/>

									<AppBreadCrumb msgInfo="Hello!, there is no notification today for you" />

									<div className="layout-content">
										<AppRouter />
									</div>

									<AppClock />
								</div>
							</div>
						);
					}}
				/>
			</Switch>
		);
	}
}

export default App;
