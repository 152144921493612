import axios from "axios";

import * as Constants from "../constants/constants";

export default class StationService {
	jwt = localStorage.getItem(Constants.STORAGE_JWT);

	stationList() {
		return axios({
			method: "get",
			baseURL: Constants.SERVER_URL,
			url: "/sentinel/station/list",
			timeout: 4000,
			headers: {
				Authorization: "Bearer " + this.jwt,
			},
		});
	}

	stationAdd() {}

	stationDelete() {}

	recordList() {
		return axios({
			method: "get",
			baseURL: Constants.SERVER_URL,
			url: "/sentinel/record/list",
			timeout: 4000,
			headers: {
				Authorization: "Bearer " + this.jwt,
			},
		});
	}

	monitorList() {
		return axios({
			method: "get",
			baseURL: Constants.SERVER_URL,
			url: "/sentinel/monitor/list",
			timeout: 4000,
			headers: {
				Authorization: "Bearer " + this.jwt,
			},
		});
	}

	monitorLastMinutes(mins, station) {
		return axios({
			method: "get",
			baseURL: Constants.SERVER_URL,
			url: `/sentinel/monitor/last?minutes=${mins}&station=${station}`,
			timeout: 4000,
			headers: {
				Authorization: "Bearer " + this.jwt,
			},
		});
	}

	backdoor(imei) {
		return axios({
			method: "post",
			baseURL: Constants.SERVER_URL,
			url: "/sentinel/station/backdoor",
			timeout: 4000,
			data: { station_uuid: imei },
			headers: {
				Authorization: "Bearer " + this.jwt,
			},
		});
	}
}
