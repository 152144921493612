import React, { Component } from "react";

import { Chart } from "primereact/chart";
import { Dropdown } from "primereact/dropdown";

import StationService from "../services/StationService";

export default class AppStationChart extends Component {
	basicOptions = {
		animation: {
			duration: 0,
		},
		responsive: true,
		legend: {
			labels: {
				fontColor: "#495057",
			},
		},
		scales: {
			xAxes: [
				{
					ticks: {
						fontColor: "#495057",
					},
				},
			],
			yAxes: [
				{
					ticks: {
						fontColor: "#495057",
					},
				},
			],
		},
	};

	constructor(props) {
		super(props);

		this.state = {
			stationList: [],
			selectedStation: null,

			tempAmbient: {
				labels: [],
				datasets: [
					{
						label: "HiPB",
						data: [],
						fill: true,
						borderColor: "rgb(54,162,235)",
						backgroundColor: "rgba(54,162,235,0.2)",
					},
				],
			},
			signalStrength: {
				labels: [],
				datasets: [
					{
						label: "HiPB",
						data: [],
						fill: true,
						borderColor: "rgb(255,99,132)",
						backgroundColor: "rgba(255,99,132,0.2)",
					},
				],
			},
		};

		this.stationService = new StationService();
		this.updateChartData = this.updateChartData.bind(this);
		this.updateChartDataTest = this.updateChartDataTest.bind(this);
		this.updateStationList = this.updateStationList.bind(this);
		this.onStationChange = this.onStationChange.bind(this);
	}

	componentDidMount() {
		this.updateStationList();
	}

	componentWillUnmount() {
		clearInterval(this.timerID);
	}

	async updateChartData(mins, station) {
		try {
			let resp = await this.stationService.monitorLastMinutes(mins, station);

			let time_list = resp.data.map((element) => {
				let now_gmt7 = new Date(Date.parse(element.time) + 3600 * 7 * 1000);
				return now_gmt7.toISOString().slice(11, 16);
			});
			let temp_a_list = resp.data.map((element) => {
				return element.temperature_a;
			});
			let signal_strength_list = resp.data.map((element) => {
				return element.signal_strength;
			});
			let tempAmbient = { ...this.state.tempAmbient };
			tempAmbient.labels = time_list;
			tempAmbient.datasets[0].data = temp_a_list;
			tempAmbient.datasets[0].label = this.state.selectedStation;
			let signalStrength = { ...this.state.signalStrength };
			signalStrength.labels = time_list;
			signalStrength.datasets[0].data = signal_strength_list;
			signalStrength.datasets[0].label = this.state.selectedStation;
			this.setState({
				tempAmbient: tempAmbient,
				signalStrength: signalStrength,
			});

			// console.log(resp.data);
		} catch (error) {
			console.log(error.message);
		}
	}

	updateChartDataTest() {
		let tempAmbient = { ...this.state.tempAmbient };
		tempAmbient.labels.push("test-time");
		tempAmbient.datasets[0].data.push(Math.random() * (34 - 24) + 24);
		let signalStrength = { ...this.state.signalStrength };
		signalStrength.labels.push("test-time");
		signalStrength.datasets[0].data.push(Math.random() * (30 - 20) + 20);
		this.setState({
			tempAmbient: tempAmbient,
			signalStrength: signalStrength,
		});
	}

	async updateStationList() {
		try {
			let resp = await this.stationService.stationList();
			let station_list = resp.data.map((element) => {
				let map_obj = {
					label: element.station_name,
					value: element.station_uuid,
				};
				return map_obj;
			});
			this.setState({ stationList: station_list });

			// console.log(station_list);
		} catch (error) {
			console.log(error.message);
		}
	}

	onStationChange(e) {
		this.updateChartData(360, e.value);
		clearInterval(this.timerID);
		this.timerID = setInterval(() => this.updateChartData(360, e.value), 5000);
		this.setState({ selectedStation: e.value });
	}

	render() {
		return (
			<div className="p-grid">
				<div className="p-col-12 p-lg-12">
					<div className="card">
						<h5>Filter</h5>
						<Dropdown
							placeholder="Select a Station"
							value={this.state.selectedStation}
							options={this.state.stationList}
							optionLabel="label"
							optionValue="label"
							onChange={this.onStationChange}
							filter
							filterInputAutoFocus={false}
							showClear
							filterBy="label"
							style={{ width: "20rem" }}
						/>
					</div>
				</div>
				<div className="p-col-12 p-lg-6">
					<div className="card">
						<h5>Temperature Ambient (˚C)</h5>
						<Chart
							type="line"
							data={this.state.tempAmbient}
							options={this.basicOptions}
						/>
					</div>
				</div>

				<div className="p-col-12 p-lg-6">
					<div className="card">
						<h5>Signal Strength</h5>
						<Chart
							type="line"
							data={this.state.signalStrength}
							options={this.basicOptions}
						/>
					</div>
				</div>
			</div>
		);
	}
}
