import React, { Component } from "react";

import { Link } from "react-router-dom";

export default class AppTopbar extends Component {
	constructor(props) {
		super(props);

		this.state = {
			visibleUserMenu: false,
			visibleSearchBar: false,
		};

		this.onMobileSidebarBtnClick = this.onMobileSidebarBtnClick.bind(this);
		this.onMobileTopbarBtnClick = this.onMobileTopbarBtnClick.bind(this);
		this.onMobileSearchBtnClick = this.onMobileSearchBtnClick.bind(this);
		this.onMobileUserMenuClick = this.onMobileUserMenuClick.bind(this);

		this.onUserMenuClick = this.onUserMenuClick.bind(this);
		this.onOutsideUserMenuClick = this.onOutsideUserMenuClick.bind(this);
		this.onKeyEscapeDown = this.onKeyEscapeDown.bind(this);

		this.onSignoutClick = this.onSignoutClick.bind(this);
	}

	onMobileSidebarBtnClick() {
		this.props.mobileSidebarHandler();
	}

	onMobileTopbarBtnClick() {
		this.props.mobileTopbarHandler();
	}

	onMobileUserMenuClick() {
		this.setState((prevState) => ({
			visibleUserMenu: !prevState.visibleUserMenu,
		}));
	}

	onMobileSearchBtnClick() {
		this.setState((prevState) => ({
			visibleSearchBar: !prevState.visibleSearchBar,
		}));
	}

	onUserMenuClick() {
		if (!this.state.visibleUserMenu) {
			// attach/remove event handler
			document.addEventListener("click", this.onOutsideUserMenuClick, false);
			document.addEventListener("keydown", this.onKeyEscapeDown, false);
		} else {
			document.removeEventListener("click", this.onOutsideUserMenuClick, false);
			document.removeEventListener("keydown", this.onKeyEscapeDown, false);
		}

		this.setState((prevState) => ({
			visibleUserMenu: !prevState.visibleUserMenu,
		}));
	}

	onOutsideUserMenuClick(e) {
		// ignore clicks on the component itself
		if (this.node.contains(e.target)) {
			return;
		}

		this.onUserMenuClick();
	}

	onKeyEscapeDown(e) {
		if (e.key === "Escape") {
			this.onUserMenuClick();
		}
	}

	onSignoutClick() {
		console.log(localStorage);
		localStorage.clear();
		console.log(localStorage);
		window.location.href = "/login";
	}

	render() {
		return (
			<div className="layout-topbar">
				<div className="layout-topbar-wrapper">
					<div className="layout-topbar-left">
						<div className="layout-topbar-logo-wrapper">
							<a className="layout-topbar-logo" href="/">
								<img
									alt="mirage-layout"
									src={require("../assets/images/logo.png").default}
								/>
								<span className="app-name">Vớ Vẩn</span>
							</a>
						</div>
						<Link
							to="#"
							className="sidebar-menu-button"
							onClick={this.onMobileSidebarBtnClick}
						>
							<i className="pi pi-bars"></i>
						</Link>
						<Link
							to="#"
							className="topbar-menu-mobile-button"
							onClick={this.onMobileTopbarBtnClick}
						>
							<i className="pi pi-ellipsis-v"></i>
						</Link>
					</div>

					<div className="layout-topbar-right fadeInDown">
						<ul className="layout-topbar-actions">
							<li
								className={`search-item topbar-item ${
									this.state.visibleSearchBar ? "active-topmenuitem" : ""
								}`}
							>
								<Link
									to="#"
									className="topbar-search-mobile-button"
									onClick={this.onMobileSearchBtnClick}
								>
									<i className="topbar-icon pi pi-search"></i>
								</Link>
								<ul className="search-item-submenu fadeInDown">
									<li>
										<span className="md-inputfield search-input-wrapper">
											<input
												pinputtext=""
												placeholder="Search..."
												className="p-inputtext p-component"
											/>
											<i className="pi pi-search"></i>
										</span>
									</li>
								</ul>
							</li>

							<li className="topbar-item">
								<a href="/#">
									<i className="topbar-icon pi pi-calendar"></i>
								</a>
							</li>
							<li className="topbar-item">
								<a href="/#">
									<i className="topbar-icon pi pi-inbox"></i>
								</a>
							</li>
							<li className="topbar-item">
								<a href="/#">
									<i className="topbar-icon pi pi-envelope"></i>
								</a>
							</li>
							<li
								className={`topbar-item profile-item ${
									this.state.visibleUserMenu ? "active-topmenuitem" : ""
								}`}
								ref={(node) => {
									this.node = node;
								}}
							>
								<Link to="#" onClick={this.onUserMenuClick}>
									<span className="profile-image-wrapper">
										<img
											alt="mirage-layout"
											src={require("../assets/images/batman.png").default}
										/>
									</span>
									<span className="profile-info-wrapper">
										<h3>{this.props.userName}</h3>
										<span>{this.props.userRole}</span>
									</span>
								</Link>
								<ul className="profile-item-submenu fadeInDown">
									<li className="profile-submenu-header">
										<div className="performance">
											<span>Weekly Performance</span>
											<img
												alt="mirage-layout"
												src={
													require("../assets/layout/images/topbar/asset-bars.svg")
														.default
												}
											/>
										</div>
										<div className="profile">
											<img
												alt="mirage-layout"
												src={require("../assets/images/batman.png").default}
												width="40"
											/>
											<h1>{this.props.userAka}</h1>
											<span>{this.props.userRole}</span>
										</div>
									</li>
									<li className="layout-submenu-item">
										<i className="pi pi-list icon icon-1"></i>
										<div className="menu-text">
											<p>Tasks</p>
											<span>3 open issues</span>
										</div>
										<i className="pi pi-angle-right"></i>
									</li>
									<li className="layout-submenu-item">
										<i className="pi pi-shopping-cart icon icon-2"></i>
										<div className="menu-text">
											<p>Payments</p>
											<span>24 new</span>
										</div>
										<i className="pi pi-angle-right"></i>
									</li>
									<li className="layout-submenu-item">
										<i className="pi pi-users icon icon-3"></i>
										<div className="menu-text">
											<p>Clients</p>
											<span>+80%</span>
										</div>
										<i className="pi pi-angle-right"></i>
									</li>
									<li className="layout-submenu-footer">
										<button className="buy-mirage-button">Settings</button>
										<button
											className="signout-button"
											onClick={this.onSignoutClick}
										>
											Sign Out
										</button>
									</li>
								</ul>
							</li>
						</ul>

						<ul className="profile-mobile-wrapper">
							<li
								className={`topbar-item profile-item ${
									this.state.visibleUserMenu ? "active-topmenuitem" : ""
								}`}
							>
								<Link to="#" onClick={this.onMobileUserMenuClick}>
									<span className="profile-image-wrapper">
										<img
											alt="mirage-layout"
											src={require("../assets/images/batman.png").default}
										/>
									</span>
									<span className="profile-info-wrapper">
										<h3>{this.props.userName}</h3>
										<span>{this.props.userRole}</span>
									</span>
								</Link>
								<ul className="fadeInDown">
									<li className="profile-submenu-header">
										<div className="performance">
											<span>Weekly Performance</span>
											<img
												alt="mirage-layout"
												src={
													require("../assets/layout/images/topbar/asset-bars.svg")
														.default
												}
											/>
										</div>
										<div className="profile">
											<img
												alt="mirage-layout"
												src={require("../assets/images/batman.png").default}
												width="45"
											/>
											<h1>{this.props.userAka}</h1>
											<span>{this.props.role}</span>
										</div>
									</li>
									<li>
										<i className="pi pi-list icon icon-1"></i>
										<div className="menu-text">
											<p>Tasks</p>
											<span>3 open issues</span>
										</div>
										<i className="pi pi-angle-right"></i>
									</li>
									<li>
										<i className="pi pi-shopping-cart icon icon-2"></i>
										<div className="menu-text">
											<p>Payments</p>
											<span>24 new</span>
										</div>
										<i className="pi pi-angle-right"></i>
									</li>
									<li>
										<i className="pi pi-users icon icon-3"></i>
										<div className="menu-text">
											<p>Clients</p>
											<span>+80%</span>
										</div>
										<i className="pi pi-angle-right"></i>
									</li>
									<li className="layout-submenu-footer">
										<button className="buy-mirage-button">Settings</button>
										<button
											className="signout-button"
											onClick={this.onSignoutClick}
										>
											Sign Out
										</button>
									</li>
								</ul>
							</li>
						</ul>
					</div>
				</div>
			</div>
		);
	}
}
