import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import AppDashboard from "../components/Dashboard";
import AppSitemap from "../components/Sitemap";
import AppStationRecord from "../components/StationRecord";
import AppStationTable from "../components/StationTable";
import AppStationChart from "../components/StationChart";
import AppStationMap from "../components/StationMap";
import AppDeviceTable from "../components/DeviceTable";
import AppUserTable from "../components/UserTable";
import AppLog from "../components/Log";

export default class AppRouter extends Component {
	render() {
		return (
			<Switch>
				<Route exact path="/">
					<Redirect to="/dashboard" />
				</Route>

				<Route exact path="/dashboard" component={AppDashboard} />

				<Route exact path="/sitemap" component={AppSitemap} />

				<Route exact path="/stations" component={AppStationRecord} />

				<Route exact path="/stations/chart" component={AppStationChart} />

				<Route exact path="/stations/table" component={AppStationTable} />

				<Route exact path="/stations/map" component={AppStationMap} />

				<Route exact path="/devices" component={AppDeviceTable} />

				<Route exact path="/users" component={AppUserTable} />

				<Route exact path="/firmwares" component={AppDeviceTable} />

				<Route exact path="/logs" component={AppLog} />

				<Route exact path="*">
					<Redirect to="/404" />
				</Route>
			</Switch>
		);
	}
}
