import axios from "axios";

import * as Constants from "../constants/constants";

export default class LogService {
	logPowerUp() {
		return axios({
			method: "get",
			baseURL: Constants.SERVER_URL,
			url: "/sentinel/log/powerup",
			timeout: 4000,
		});
	}
}
