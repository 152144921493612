import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

export default class AppSidebar extends Component {
	defaultMenu = {
		selectedDashboard: false,
		selectedSitemap: false,
		selectedStation: false,
		selectedStationTable: false,
		selectedStationChart: false,
		selectedStationMap: false,
		selectedDevice: false,
		selectedCustomer: false,
		selectedFirmware: false,
		selectedLog: false,
	};

	constructor(props) {
		super(props);

		this.state = {
			menu: this.defaultMenu,
		};

		this.onSelect = this.onSelect.bind(this);
	}

	componentDidMount() {
		let initMenuState = { menu: { ...this.defaultMenu } };
		initMenuState.menu.selectedDashboard = true;
		this.onSelect(window.location.pathname);
	}

	onSelect(tab) {
		let newMenuState = { menu: { ...this.defaultMenu } };

		if (tab === "/" || tab === "/dashboard") {
			newMenuState.menu.selectedDashboard = true;
		} else if (tab === "/sitemap") {
			newMenuState.menu.selectedSitemap = true;
		} else if (tab === "/stations") {
			newMenuState.menu.selectedStation = true;
		} else if (tab === "/stations/chart") {
			newMenuState.menu.selectedStation = true;
			newMenuState.menu.selectedStationChart = true;
		} else if (tab === "/stations/table") {
			newMenuState.menu.selectedStation = true;
			newMenuState.menu.selectedStationTable = true;
		} else if (tab === "/stations/map") {
			newMenuState.menu.selectedStation = true;
			newMenuState.menu.selectedStationMap = true;
		} else if (tab === "/devices") {
			newMenuState.menu.selectedDevice = true;
		} else if (tab === "/users") {
			newMenuState.menu.selectedCustomer = true;
		} else if (tab === "/firmwares") {
			newMenuState.menu.selectedFirmware = true;
		} else if (tab === "/logs") {
			newMenuState.menu.selectedLog = true;
		}

		this.setState(newMenuState);
		this.props.mobileHandler();
	}

	render() {
		return (
			<React.Fragment>
				<div className="sidebar-logo">
					<Link
						to="/"
						className="logo"
						aria-label="App logo"
						onClick={() => this.onSelect("/")}
					>
						<img
							alt="logo"
							src={require("../assets/images/logo.png").default}
						/>
						<span className="app-name">Vớ vẩn</span>
					</Link>
				</div>
				<div className="layout-menu-container">
					<ul className="layout-menu">
						<li
							className={
								this.state.menu.selectedDashboard ? "active-menuitem" : ""
							}
							data-tip
							data-for="dashboard"
						>
							<Link to="/" onClick={() => this.onSelect("/")}>
								<span className="menuitem-text">Dashboard</span>
								<i className="pi pi-fw pi-home"></i>
							</Link>
							<ReactTooltip id="dashboard" type="dark" effect="solid">
								<span>Dashboard</span>
							</ReactTooltip>
						</li>
						<li
							className={
								this.state.menu.selectedSitemap ? "active-menuitem" : ""
							}
							data-tip
							data-for="sitemap"
						>
							<Link to="/sitemap" onClick={() => this.onSelect("/sitemap")}>
								<span className="menuitem-text">Sitemap</span>
								<i className="pi pi-fw pi-sitemap"></i>
							</Link>
							<ReactTooltip id="sitemap" type="dark" effect="solid">
								<span>Sitemap</span>
							</ReactTooltip>
						</li>
						<li
							className={
								this.state.menu.selectedStation ? "active-menuitem" : ""
							}
							data-tip
							data-for="stations"
						>
							<Link to="/stations" onClick={() => this.onSelect("/stations")}>
								<span className="menuitem-text">Stations</span>
								<i
									className={`pi pi-fw pi-star${
										this.state.menu.selectedStation ? "-o" : ""
									}`}
								></i>
							</Link>
							<ReactTooltip
								id="stations"
								offset={{ top: 70 }}
								type="dark"
								effect="solid"
							>
								<span>Stations</span>
							</ReactTooltip>
							<ul>
								<li
									className={
										this.state.menu.selectedStationChart
											? "active-menuitem"
											: ""
									}
									data-tip
									data-for="station-chart"
								>
									<Link
										to="/stations/chart"
										onClick={() => this.onSelect("/stations/chart")}
									>
										<span className="menuitem-text">Chart</span>
										<i className="pi pi-fw pi-chart-bar"></i>
									</Link>
									<ReactTooltip
										id="station-chart"
										offset={{ right: 30 }}
										type="dark"
										effect="solid"
									>
										<span>/Chart</span>
									</ReactTooltip>
								</li>
								<li
									className={
										this.state.menu.selectedStationTable
											? "active-menuitem"
											: ""
									}
									data-tip
									data-for="station-table"
								>
									<Link
										to="/stations/table"
										onClick={() => this.onSelect("/stations/table")}
									>
										<span className="menuitem-text">Table</span>
										<i className="pi pi-fw pi-table"></i>
									</Link>
									<ReactTooltip
										id="station-table"
										offset={{ right: 30 }}
										type="dark"
										effect="solid"
									>
										<span>/Table</span>
									</ReactTooltip>
								</li>
								<li
									className={
										this.state.menu.selectedStationMap ? "active-menuitem" : ""
									}
									data-tip
									data-for="station-map"
								>
									<Link
										to="/stations/map"
										onClick={() => this.onSelect("/stations/map")}
									>
										<span className="menuitem-text">Map</span>
										<i className="pi pi-fw pi-map-marker"></i>
									</Link>
									<ReactTooltip
										id="station-map"
										offset={{ right: 30 }}
										type="dark"
										effect="solid"
									>
										<span>/Map</span>
									</ReactTooltip>
								</li>
							</ul>
						</li>
						<li
							className={
								this.state.menu.selectedDevice ? "active-menuitem" : ""
							}
							data-tip
							data-for="devices"
						>
							<Link to="/devices" onClick={() => this.onSelect("/devices")}>
								<span className="menuitem-text">Devices</span>
								<i className="pi pi-fw pi-id-card"></i>
							</Link>
							<ReactTooltip id="devices" type="dark" effect="solid">
								<span>Devices</span>
							</ReactTooltip>
						</li>
						<li
							className={
								this.state.menu.selectedCustomer ? "active-menuitem" : ""
							}
							data-tip
							data-for="users"
						>
							<Link to="/users" onClick={() => this.onSelect("/users")}>
								<span className="menuitem-text">Users</span>
								<i className="pi pi-fw pi-users"></i>
							</Link>
							<ReactTooltip id="users" type="dark" effect="solid">
								<span>Users</span>
							</ReactTooltip>
						</li>
						<li
							className={
								this.state.menu.selectedFirmware ? "active-menuitem" : ""
							}
							data-tip
							data-for="firmwares"
						>
							<Link to="/firmwares" onClick={() => this.onSelect("/firmwares")}>
								<span className="menuitem-text">Firmwares</span>
								<i className="pi pi-fw pi-upload"></i>
							</Link>
							<ReactTooltip id="firmwares" type="dark" effect="solid">
								<span>Firmwares</span>
							</ReactTooltip>
						</li>
						<li
							className={this.state.menu.selectedLog ? "active-menuitem" : ""}
							data-tip
							data-for="logs"
						>
							<Link to="/logs" onClick={() => this.onSelect("/logs")}>
								<span className="menuitem-text">Logs</span>
								<i className="pi pi-fw pi-eye"></i>
							</Link>
							<ReactTooltip id="logs" type="dark" effect="solid">
								<span>Logs</span>
							</ReactTooltip>
						</li>
					</ul>
				</div>
			</React.Fragment>
		);
	}
}
