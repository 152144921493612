import React, { Component } from "react";

import { TabView, TabPanel } from "primereact/tabview";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";

import StationService from "../services/StationService";

export default class AppStationRecord extends Component {
	emptyRecord = {
		time: null,
		station_uuid: "",
		station_name: "",
		device_uuid: "",
		device_owner: "",
		temperature_o: "",
	};

	emptyMonitor = {
		time: null,
		station_uuid: "",
		station_name: "",
		temperature_a: "",
		signal_strength: "",
		fw_scanner: "",
		fw_cellular: "",
	};

	constructor(props) {
		super(props);

		this.state = {
			record: { ...this.emptyRecord },
			recordList: [],
			monitor: { ...this.emptyMonitor },
			monitorList: [],
			globalFilter: null,
		};

		this.stationService = new StationService();
		this.exportCSV = this.exportCSV.bind(this);

		this.testService = this.testService.bind(this);
		this.updateRecordList = this.updateRecordList.bind(this);

		this.timeBodyTemplate = this.timeBodyTemplate.bind(this);
		this.uuidStationBodyTemplate = this.uuidStationBodyTemplate.bind(this);
		this.nameStationBodyTemplate = this.nameStationBodyTemplate.bind(this);
		this.uuidDeviceBodyTemplate = this.uuidDeviceBodyTemplate.bind(this);
		this.ownerDeviceBodyTemplate = this.ownerDeviceBodyTemplate.bind(this);
		this.tempFormatter = this.tempFormatter.bind(this);
		this.tempOBodyTemplate = this.tempOBodyTemplate.bind(this);
		this.tempABodyTemplate = this.tempABodyTemplate.bind(this);
		this.signalBodyTemplate = this.signalBodyTemplate.bind(this);
		this.fwscannerBodyTemplate = this.fwscannerBodyTemplate.bind(this);
		this.fwcellularBodyTemplate = this.fwcellularBodyTemplate.bind(this);
	}

	componentDidMount() {
		this.updateRecordList();
		this.updateMonitorList();
	}

	async testService(imei) {
		try {
			let res = await this.stationService.recordList();
			console.log(res);
			let resp = await this.stationService.backdoor(imei);
			console.log(resp);
		} catch (error) {
			console.log(error.message);
		}
	}

	async updateRecordList() {
		try {
			let resp = await this.stationService.recordList();
			let map_list = resp.data.map((element) => {
				let map_obj = {};
				let now_gmt7 = new Date(Date.parse(element.time) + 3600 * 7 * 1000);
				map_obj.time = now_gmt7.toISOString();
				map_obj.station_uuid = element.station_uuid;
				map_obj.station_name = element.station_name;
				map_obj.device_uuid = element.device_uuid;
				map_obj.device_owner = element.device_owner;
				map_obj.temperature_o = element.temperature_o;
				return map_obj;
			});
			this.setState({ recordList: map_list });
			// console.log(map_list);
		} catch (error) {
			console.log(error.message);
		}
	}

	async updateMonitorList() {
		try {
			let resp = await this.stationService.monitorList();
			let map_list = resp.data.map((element) => {
				let map_obj = {};
				let now_gmt7 = new Date(Date.parse(element.time) + 3600 * 7 * 1000);
				map_obj.time = now_gmt7.toISOString();
				map_obj.station_uuid = element.station_uuid;
				map_obj.station_name = element.station_name;
				map_obj.temperature_a = element.temperature_a;
				map_obj.signal_strength = element.signal_strength;
				map_obj.fw_scanner = element.fw_scanner;
				map_obj.fw_cellular = element.fw_cellular;
				return map_obj;
			});
			this.setState({ monitorList: map_list });
			// console.log(map_list);
		} catch (error) {
			console.log(error.message);
		}
	}

	exportCSV() {
		this.dt.exportCSV();
	}

	timeBodyTemplate(rowData) {
		return (
			<React.Fragment>
				<span className="p-column-title">Created At</span>
				{rowData.time}
			</React.Fragment>
		);
	}

	uuidStationBodyTemplate(rowData) {
		return (
			<React.Fragment>
				<span className="p-column-title">Station IMEI</span>
				{rowData.station_uuid}
			</React.Fragment>
		);
	}

	nameStationBodyTemplate(rowData) {
		return (
			<React.Fragment>
				<span className="p-column-title">Station Name</span>
				{rowData.station_name}
			</React.Fragment>
		);
	}

	uuidDeviceBodyTemplate(rowData) {
		return (
			<React.Fragment>
				<span className="p-column-title">Device IMEI</span>
				{rowData.device_uuid}
			</React.Fragment>
		);
	}

	ownerDeviceBodyTemplate(rowData) {
		return (
			<React.Fragment>
				<span className="p-column-title">Device Owner</span>
				{rowData.device_owner}
			</React.Fragment>
		);
	}

	tempFormatter(rowData) {
		if (rowData.temperature_o < 35.0) {
			return (
				<React.Fragment>
					<p style={{ color: "LightGray" }} align="center">
						<b>{rowData.temperature_o.toFixed(1)} ˚C</b>
					</p>
				</React.Fragment>
			);
		} else if (rowData.temperature_o > 37.0) {
			return (
				<React.Fragment>
					<p style={{ color: "red" }} align="center">
						<b>{rowData.temperature_o.toFixed(1)} ˚C</b>
					</p>
				</React.Fragment>
			);
		} else {
			return (
				<React.Fragment>
					<p style={{ color: "black" }} align="center">
						{rowData.temperature_o.toFixed(1)} ˚C
					</p>
				</React.Fragment>
			);
		}
	}

	tempOBodyTemplate(rowData) {
		return (
			<React.Fragment>
				<span className="p-column-title">Temperature (object)</span>
				{this.tempFormatter(rowData)}
			</React.Fragment>
		);
	}

	tempABodyTemplate(rowData) {
		return (
			<React.Fragment>
				<span className="p-column-title">Temperature (ambient) </span>
				{rowData.temperature_a.toFixed(1)} ˚C
			</React.Fragment>
		);
	}

	signalBodyTemplate(rowData) {
		return (
			<React.Fragment>
				<span className="p-column-title">Signal Strength</span>
				{rowData.signal_strength}
			</React.Fragment>
		);
	}

	fwscannerBodyTemplate(rowData) {
		return (
			<React.Fragment>
				<span className="p-column-title">FW Scanner</span>
				{rowData.fw_scanner}
			</React.Fragment>
		);
	}

	fwcellularBodyTemplate(rowData) {
		return (
			<React.Fragment>
				<span className="p-column-title">FW Cellular</span>
				{rowData.fw_cellular}
			</React.Fragment>
		);
	}

	render() {
		const header = (
			<div className="table-header">
				<span></span>
				<span className="p-input-icon-left">
					<i className="pi pi-search" />
					<InputText
						type="search"
						onInput={(e) => this.setState({ globalFilter: e.target.value })}
						placeholder="Search..."
					/>
				</span>
			</div>
		);

		const paginatorLeft = (
			<Button type="button" icon="pi pi-refresh" className="p-button-text" />
		);
		const paginatorRight = (
			<Button type="button" icon="pi pi-cloud" className="p-button-text" />
		);

		const leftContents = <React.Fragment></React.Fragment>;

		const rightContents = (
			<span className="p-buttonset">
				<Button
					label="Export to CSV"
					className="p-button-raised p-button-success"
					icon="pi pi-cloud-download"
					onClick={this.exportCSV}
				/>
			</span>
		);

		return (
			<div className="datatable-crud-demo datatable-responsive-demo">
				<Toast ref={(el) => (this.toast = el)} />

				<TabView>
					<TabPanel header="Record">
						<Toolbar left={leftContents} right={rightContents} />
						<DataTable
							value={this.state.recordList}
							ref={(el) => {
								this.dt = el;
							}}
							// selection={this.state.selectedList}
							// onSelectionChange={(e) =>
							// 	this.setState({ selectedList: e.value })
							// }
							// selectionMode="multiple"
							// metaKeySelection={false}
							style={{ marginTop: "1em" }}
							className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive-demo"
							paginator
							paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
							currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
							rows={12}
							rowsPerPageOptions={[12, 25, 50, 100]}
							paginatorLeft={paginatorLeft}
							paginatorRight={paginatorRight}
							globalFilter={this.state.globalFilter}
							header={header}
						>
							<Column
								field="time"
								header="Created At"
								body={this.timeBodyTemplate}
								sortable
							></Column>
							<Column
								field="station_uuid"
								header="Station IMEI"
								body={this.uuidStationBodyTemplate}
								sortable
							></Column>
							<Column
								field="station_name"
								header="Station Name"
								body={this.nameStationBodyTemplate}
								sortable
							></Column>
							<Column
								field="device_uuid"
								header="Device IMEI"
								body={this.uuidDeviceBodyTemplate}
								sortable
							></Column>
							<Column
								field="device_owner"
								header="Device Owner"
								body={this.ownerDeviceBodyTemplate}
								sortable
							></Column>
							<Column
								field="temperature_o"
								header="Temperature (object)"
								body={this.tempOBodyTemplate}
								sortable
							></Column>
						</DataTable>
					</TabPanel>
					<TabPanel header="Monitor">
						<Toolbar left={leftContents} right={rightContents} />
						<DataTable
							value={this.state.monitorList}
							ref={(el) => {
								this.dt = el;
							}}
							// selection={this.state.selectedList}
							// onSelectionChange={(e) =>
							// 	this.setState({ selectedList: e.value })
							// }
							// selectionMode="multiple"
							// metaKeySelection={false}
							style={{ marginTop: "1em" }}
							className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive-demo"
							paginator
							paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
							currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
							rows={12}
							rowsPerPageOptions={[12, 25, 50, 100]}
							paginatorLeft={paginatorLeft}
							paginatorRight={paginatorRight}
							globalFilter={this.state.globalFilter}
							header={header}
						>
							<Column
								field="time"
								header="Created At"
								body={this.timeBodyTemplate}
								sortable
							></Column>
							<Column
								field="station_uuid"
								header="Station IMEI"
								body={this.uuidStationBodyTemplate}
								sortable
							></Column>
							<Column
								field="station_name"
								header="Station Name"
								body={this.nameStationBodyTemplate}
								sortable
							></Column>
							<Column
								field="temperature_a"
								header="Temperature (ambient)"
								body={this.tempABodyTemplate}
								sortable
							></Column>
							<Column
								field="signal_strength"
								header="Signal Strength"
								body={this.signalBodyTemplate}
								sortable
							></Column>
							<Column
								field="fw_scanner"
								header="FW Scanner"
								body={this.fwscannerBodyTemplate}
								sortable
							></Column>
							<Column
								field="fw_cellular"
								header="FW Cellular"
								body={this.fwcellularBodyTemplate}
								sortable
							></Column>
						</DataTable>
					</TabPanel>
				</TabView>
			</div>
		);
	}
}
