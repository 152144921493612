import axios from "axios";

import * as Constants from "../constants/constants";

export default class DeviceService {
	jwt = localStorage.getItem(Constants.STORAGE_JWT);

	list() {
		return axios({
			method: "get",
			baseURL: Constants.SERVER_URL,
			url: "/sentinel/device/list",
			timeout: 4000,
			headers: {
				Authorization: "Bearer " + this.jwt,
			},
		});
	}

	add() {}

	delete() {}
}
