import React, { Component } from "react";

import SummaryService from "../services/SummaryService";

export default class AppDashboard extends Component {
	emptySummary = {
		record: {
			totalCnt: 0,
			todayCnt: 0,
			yesterdayCnt: 0,
			todayWarningCnt: 0,
			yesterdayWarningCnt: 0,
		},
		monitor: {
			totalCnt: 0,
			todayCnt: 0,
			yesterdayCnt: 0,
		},
	};

	constructor(props) {
		super(props);
		this.state = this.emptySummary;

		this.summaryService = new SummaryService();
		this.updateSummary = this.updateSummary.bind(this);
	}

	componentDidMount() {
		this.updateSummary();
	}

	async updateSummary() {
		try {
			let resp = await this.summaryService.summarizeToday();
			let map_obj = {
				record: {
					totalCnt: resp.data.record.totalCnt,
					todayCnt: resp.data.record.todayCnt,
					yesterdayCnt: resp.data.record.yesterdayCnt,
					todayWarningCnt: resp.data.record.todayWarningCnt,
					yesterdayWarningCnt: resp.data.record.yesterdayWarningCnt,
				},
				monitor: {
					totalCnt: resp.data.monitor.totalCnt,
					todayCnt: resp.data.monitor.todayCnt,
					yesterdayCnt: resp.data.monitor.yesterdayCnt,
				},
			};
			this.setState(map_obj);
			// console.log(resp.data);
		} catch (error) {
			console.log(error.message);
		}
	}

	render() {
		return (
			<div className="layout-dashboard">
				<div className="p-grid">
					<div className="p-col-12 p-md-6 p-xl-3">
						<div className="card no-gutter widget-overview-box widget-overview-box-1">
							<span className="overview-icon">
								<i className="pi pi-th-large"></i>
							</span>
							<span className="overview-title">Record</span>
							<div className="p-grid overview-detail">
								<div className="p-col-6">
									<div className="overview-number">
										{this.state.record.todayCnt}
									</div>
									<div className="overview-subtext">Scanned today</div>
								</div>
								<div className="p-col-6">
									<div className="overview-number">
										{this.state.record.yesterdayCnt}
									</div>
									<div className="overview-subtext">Scanned yesterday</div>
								</div>
							</div>
						</div>
					</div>

					<div className="p-col-12 p-md-6 p-xl-3">
						<div className="card no-gutter widget-overview-box widget-overview-box-2">
							<span className="overview-icon">
								<i className="pi pi-filter"></i>
							</span>
							<span className="overview-title">Warning</span>
							<div className="p-grid overview-detail">
								<div className="p-col-6">
									<div className="overview-number">
										{this.state.record.todayWarningCnt}
									</div>
									<div className="overview-subtext">Warning today</div>
								</div>
								<div className="p-col-6">
									<div className="overview-number">
										{this.state.record.yesterdayWarningCnt}
									</div>
									<div className="overview-subtext">Warning yesterday</div>
								</div>
							</div>
						</div>
					</div>

					<div className="p-col-12 p-md-6 p-xl-3">
						<div className="card no-gutter widget-overview-box widget-overview-box-3">
							<span className="overview-icon">
								<i className="pi pi-users"></i>
							</span>
							<span className="overview-title">Monitor</span>
							<div className="p-grid overview-detail">
								<div className="p-col-6">
									<div className="overview-number">
										{this.state.monitor.todayCnt}
									</div>
									<div className="overview-subtext">Sent today</div>
								</div>
								<div className="p-col-6">
									<div className="overview-number">
										{this.state.monitor.yesterdayCnt}
									</div>
									<div className="overview-subtext">Sent yesterday</div>
								</div>
							</div>
						</div>
					</div>

					<div className="p-col-12 p-md-6 p-xl-3">
						<div className="card no-gutter widget-overview-box widget-overview-box-4">
							<span className="overview-icon">
								<i className="pi pi-globe"></i>
							</span>
							<span className="overview-title">Total</span>
							<div className="p-grid overview-detail">
								<div className="p-col-6">
									<div className="overview-number">
										{this.state.record.totalCnt}
									</div>
									<div className="overview-subtext">Records</div>
								</div>
								<div className="p-col-6">
									<div className="overview-number">
										{this.state.monitor.totalCnt}
									</div>
									<div className="overview-subtext">Monitors</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
