import React, { Component } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { Toolbar } from "primereact/toolbar";

import StationService from "../services/StationService";

export default class AppStationTable extends Component {
	emptyStation = {
		time: null,
		uuid: "",
		name: "",
		status: "",
		location: "",
		version: "",
		owner: "",
		firmware: "",
	};

	constructor(props) {
		super(props);

		this.state = {
			station: { ...this.emptyStation },
			stationList: [],
			selectedList: [],
			flag_DialogDelStation: false,
			flag_DialogAddStation: false,
			flag_DialogEditStation: false,
			submitted: false,
			globalFilter: null,
		};

		this.stationService = new StationService();
		this.exportCSV = this.exportCSV.bind(this);

		this.testService = this.testService.bind(this);
		this.updateStationList = this.updateStationList.bind(this);

		this.actionBodyTemplate = this.actionBodyTemplate.bind(this);

		this.timeBodyTemplate = this.timeBodyTemplate.bind(this);
		this.uuidBodyTemplate = this.uuidBodyTemplate.bind(this);
		this.nameBodyTemplate = this.nameBodyTemplate.bind(this);
		this.statusBodyTemplate = this.statusBodyTemplate.bind(this);
		this.versionBodyTemplate = this.versionBodyTemplate.bind(this);
		this.ownerBodyTemplate = this.ownerBodyTemplate.bind(this);

		this.showDialogDelStation = this.showDialogDelStation.bind(this);
		this.hideDialogDelStation = this.hideDialogDelStation.bind(this);
		this.handlerDelStation = this.handlerDelStation.bind(this);

		this.showDialogEditStation = this.showDialogEditStation.bind(this);
		this.hideDialogEditStation = this.hideDialogEditStation.bind(this);
		this.handlerEditStation = this.handlerEditStation.bind(this);

		this.showDialogAddStation = this.showDialogAddStation.bind(this);
		this.hideDialogAddStation = this.hideDialogAddStation.bind(this);
		this.onInputChange = this.onInputChange.bind(this);
		this.onCategoryChange = this.onCategoryChange.bind(this);
		this.handlerAddStation = this.handlerAddStation.bind(this);
	}

	componentDidMount() {
		this.updateStationList();
		// this.testService("TOOL_SENTINEL");
	}

	async testService(imei) {
		try {
			let res = await this.stationService.stationList();
			console.log(res);
			let resp = await this.stationService.backdoor(imei);
			console.log(resp);
		} catch (error) {
			console.log(error.message);
		}
	}

	async updateStationList() {
		try {
			let resp = await this.stationService.stationList();
			let map_list = resp.data.map((element) => {
				let map_obj = {};
				let now_gmt7 = new Date(Date.parse(element.time) + 3600 * 7 * 1000);
				map_obj.time = now_gmt7.toISOString();
				map_obj.uuid = element.station_uuid;
				map_obj.name = element.station_name;
				map_obj.status = element.station_status;
				map_obj.version = element.station_version;
				map_obj.firmware = element.station_firmware;
				map_obj.owner = element.station_owner;
				map_obj.location = element.station_location;
				return map_obj;
			});
			this.setState({ stationList: map_list });
			// console.log(map_list);
		} catch (error) {
			console.log(error.message);
		}
	}

	exportCSV() {
		this.dt.exportCSV();
	}

	showDialogAddStation() {
		this.setState({
			station: { ...this.emptyStation },
			submitted: false,
			flag_DialogAddStation: true,
		});
	}

	hideDialogAddStation() {
		this.setState({
			submitted: false,
			flag_DialogAddStation: false,
		});
	}

	onInputChange(e, name) {
		const val = (e.target && e.target.value) || "";
		let station = { ...this.state.station };
		station[`${name}`] = val;
		this.setState({ station });
	}

	onCategoryChange(e) {
		let station = { ...this.state.station };
		station["status"] = e.value;
		this.setState({ station });
	}

	handlerAddStation() {
		if (
			this.state.station.uuid.length &&
			this.state.station.name.length &&
			this.state.station.status.length
		) {
			let now = new Date();
			let now_gmt7 = new Date(now.getTime() + 3600 * 7 * 1000);

			let station = { ...this.state.station };
			station.time = now_gmt7.toISOString();

			this.setState({
				station: { ...this.emptyStation },
				stationList: [station, ...this.state.stationList],
				flag_DialogAddStation: false,
				submitted: false,
			});

			this.toast.show({
				severity: "success",
				summary: "Successful",
				detail: "Station added",
				life: 3000,
			});
		}

		this.setState({ submitted: true });
	}

	showDialogDelStation(rowData) {
		this.setState({
			station: rowData,
			flag_DialogDelStation: true,
		});
	}

	hideDialogDelStation() {
		this.setState({ flag_DialogDelStation: false });
	}

	handlerDelStation() {
		let stationList = this.state.stationList.filter(
			(val) => val.uuid !== this.state.station.uuid
		);
		this.toast.show({
			severity: "success",
			summary: "Successful",
			detail: "Station Deleted",
			life: 3000,
		});
		this.setState({
			flag_DialogDelStation: false,
			station: { ...this.emptyStation },
			stationList: stationList,
		});
	}

	showDialogEditStation(rowData) {
		this.setState({
			station: rowData,
			flag_DialogEditStation: true,
		});
	}

	hideDialogEditStation() {
		this.setState({
			submitted: false,
			flag_DialogEditStation: false,
		});
	}

	handlerEditStation(station) {
		if (
			this.state.station.uuid.length &&
			this.state.station.name.length &&
			this.state.station.status.length &&
			this.state.station.owner.length &&
			this.state.station.location.length
		) {
			let index = this.state.stationList.findIndex(
				(element) => element.uuid === this.state.station.uuid
			);
			let stationList = [...this.state.stationList];
			stationList[index].name = this.state.station.name;
			stationList[index].status = this.state.station.status;
			stationList[index].owner = this.state.station.owner;
			stationList[index].location = this.state.station.location.replace(
				/\s/g,
				""
			);

			this.setState({
				station: { ...this.emptyStation },
				stationList: stationList,
				flag_DialogEditStation: false,
				submitted: false,
			});

			this.toast.show({
				severity: "success",
				summary: "Successful",
				detail: "Station edited",
				life: 3000,
			});
		}

		this.setState({ submitted: true });
	}

	actionBodyTemplate(rowData) {
		return (
			<div align="center">
				<Button
					icon="pi pi-pencil"
					className="p-button-rounded p-button-secondary p-button-outlined p-mr-2"
					onClick={() => this.showDialogEditStation(rowData)}
				/>
				<Button
					icon="pi pi-trash"
					className="p-button-rounded p-button-secondary p-button-outlined"
					onClick={() => this.showDialogDelStation(rowData)}
				/>
			</div>
		);
	}

	timeBodyTemplate(rowData) {
		return (
			<React.Fragment>
				<span className="p-column-title">Created At</span>
				{rowData.time}
			</React.Fragment>
		);
	}

	uuidBodyTemplate(rowData) {
		return (
			<React.Fragment>
				<span className="p-column-title">IMEI</span>
				{rowData.uuid}
			</React.Fragment>
		);
	}

	nameBodyTemplate(rowData) {
		return (
			<React.Fragment>
				<span className="p-column-title">Name</span>
				{rowData.name}
			</React.Fragment>
		);
	}

	statusBodyTemplate(rowData) {
		let labelTable = {
			RELEASE: "highlight-blue",
			TEST: "highlight-yellow",
			INSTOCK: "highlight-green",
			CUSTOM: "highlight-orange",
			DAMAGED: "highlight-red",
			REFURBISHED: "highlight-purple",
		};
		return (
			<React.Fragment>
				<span className="p-column-title">Status</span>
				{
					<span className={`hl-label ${labelTable[rowData.status]}`}>
						{rowData.status}
					</span>
				}
			</React.Fragment>
		);
	}

	versionBodyTemplate(rowData) {
		return (
			<React.Fragment>
				<span className="p-column-title">Version</span>
				{`${
					rowData.version ? rowData.version + "(" + rowData.firmware + ")" : "-"
				}`}
			</React.Fragment>
		);
	}

	ownerBodyTemplate(rowData) {
		return (
			<React.Fragment>
				<span className="p-column-title">Owner</span>
				<a
					href={`${
						rowData.location
							? "https://www.google.com/maps/place/" + rowData.location
							: "/"
					}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					{`${rowData.owner ? rowData.owner : "-"}`}
				</a>
			</React.Fragment>
		);
	}

	render() {
		const header = (
			<div className="table-header">
				<span></span>
				<span className="p-input-icon-left">
					<i className="pi pi-search" />
					<InputText
						type="search"
						onInput={(e) => this.setState({ globalFilter: e.target.value })}
						placeholder="Search..."
					/>
				</span>
			</div>
		);

		const paginatorLeft = (
			<Button type="button" icon="pi pi-refresh" className="p-button-text" />
		);
		const paginatorRight = (
			<Button type="button" icon="pi pi-cloud" className="p-button-text" />
		);

		const footerDialogDelete = (
			<React.Fragment>
				<Button
					label="No"
					icon="pi pi-times"
					className="p-button-text"
					onClick={this.hideDialogDelStation}
				/>
				<Button
					label="Yes"
					icon="pi pi-check"
					className="p-button-text"
					onClick={this.handlerDelStation}
				/>
			</React.Fragment>
		);

		const footerDialogAdd = (
			<React.Fragment>
				<Button
					label="Cancel"
					icon="pi pi-times"
					className="p-button-text"
					onClick={this.hideDialogAddStation}
				/>
				<Button
					label="Add"
					icon="pi pi-check"
					className="p-button-text"
					onClick={this.handlerAddStation}
				/>
			</React.Fragment>
		);

		const footerDialogEdit = (
			<React.Fragment>
				<Button
					label="Cancel"
					icon="pi pi-times"
					className="p-button-text"
					onClick={this.hideDialogEditStation}
				/>
				<Button
					label="Save"
					icon="pi pi-check"
					className="p-button-text"
					onClick={this.handlerEditStation}
				/>
			</React.Fragment>
		);

		const leftContents = <React.Fragment></React.Fragment>;

		const rightContents = (
			<span className="p-buttonset">
				<Button
					label="Export to CSV"
					className="p-button-raised p-button-success"
					icon="pi pi-cloud-download"
					onClick={this.exportCSV}
				/>
				<Button
					label="Add"
					className="p-button-raised p-button-info"
					icon="pi pi-plus-circle "
					onClick={this.showDialogAddStation}
				/>
			</span>
		);

		return (
			<div className="datatable-crud-demo datatable-responsive-demo">
				<div className="card">
					<Toast ref={(el) => (this.toast = el)} />

					<Dialog
						visible={this.state.flag_DialogAddStation}
						style={{ width: "450px" }}
						header="Station Details"
						modal
						className="p-fluid"
						footer={footerDialogAdd}
						onHide={this.hideDialogAddStation}
					>
						<div className="p-field">
							<label htmlFor="uuid">IMEI</label>
							<InputText
								id="uuid"
								value={this.state.station.uuid}
								onChange={(e) => this.onInputChange(e, "uuid")}
								required
								autoFocus
								className={`${
									this.state.submitted && !this.state.station.uuid
										? "p-invalid"
										: ""
								}`}
							/>
							{this.state.submitted && !this.state.station.uuid && (
								<small className="p-invalid">IMEI is required.</small>
							)}
						</div>

						<div className="p-field">
							<label htmlFor="name">Name</label>
							<InputText
								id="name"
								value={this.state.station.name}
								onChange={(e) => this.onInputChange(e, "name")}
								required
								autoFocus
								className={`${
									this.state.submitted && !this.state.station.name
										? "p-invalid"
										: ""
								}`}
							/>
							{this.state.submitted && !this.state.station.name && (
								<small className="p-invalid">Name is required.</small>
							)}
						</div>

						<div className="p-field">
							<label className="p-mb-3">Status</label>
							<div className="p-formgrid p-grid">
								<div className="p-field-radiobutton p-col-6">
									<RadioButton
										id="category1"
										name="category"
										value="RELEASE"
										onChange={this.onCategoryChange}
										checked={this.state.station.status === "RELEASE"}
									/>
									<label
										htmlFor="category1"
										className="hl-label highlight-blue"
									>
										RELEASE
									</label>
								</div>
								<div className="p-field-radiobutton p-col-6">
									<RadioButton
										id="category2"
										name="category"
										value="TEST"
										onChange={this.onCategoryChange}
										checked={this.state.station.status === "TEST"}
									/>
									<label
										htmlFor="category2"
										className="hl-label highlight-yellow"
									>
										TEST
									</label>
								</div>
								<div className="p-field-radiobutton p-col-6">
									<RadioButton
										id="category3"
										name="category"
										value="INSTOCK"
										onChange={this.onCategoryChange}
										checked={this.state.station.status === "INSTOCK"}
									/>
									<label
										htmlFor="category3"
										className="hl-label highlight-green"
									>
										INSTOCK
									</label>
								</div>
								<div className="p-field-radiobutton p-col-6">
									<RadioButton
										id="category4"
										name="category"
										value="CUSTOM"
										onChange={this.onCategoryChange}
										checked={this.state.station.status === "CUSTOM"}
									/>
									<label
										htmlFor="category4"
										className="hl-label highlight-orange"
									>
										CUSTOM
									</label>
								</div>
								<div className="p-field-radiobutton p-col-6">
									<RadioButton
										id="category5"
										name="category"
										value="DAMAGED"
										onChange={this.onCategoryChange}
										checked={this.state.station.status === "DAMAGED"}
									/>
									<label htmlFor="category5" className="hl-label highlight-red">
										DAMAGED
									</label>
								</div>
								<div className="p-field-radiobutton p-col-6">
									<RadioButton
										id="category6"
										name="category"
										value="REFURBISHED"
										onChange={this.onCategoryChange}
										checked={this.state.station.status === "REFURBISHED"}
									/>
									<label
										htmlFor="category6"
										className="hl-label highlight-purple"
									>
										REFURBISHED
									</label>
								</div>
								<div className="p-field-radiobutton p-col-6">
									{this.state.submitted && !this.state.station.status && (
										<small className="p-invalid">Status is required.</small>
									)}
								</div>
							</div>
						</div>
					</Dialog>

					<Dialog
						visible={this.state.flag_DialogEditStation}
						style={{ width: "450px" }}
						header="Station Details"
						modal
						className="p-fluid"
						footer={footerDialogEdit}
						onHide={this.hideDialogEditStation}
					>
						<div className="p-field">
							<label htmlFor="uuid">IMEI</label>
							<InputText id="uuid" value={this.state.station.uuid} disabled />
						</div>
						<div className="p-field">
							<label htmlFor="name">Name</label>
							<InputText
								id="name"
								value={this.state.station.name}
								onChange={(e) => this.onInputChange(e, "name")}
								required
								autoFocus
								className={`${
									this.state.submitted && !this.state.station.name
										? "p-invalid"
										: ""
								}`}
							/>
							{this.state.submitted && !this.state.station.name && (
								<small className="p-invalid">Name is required.</small>
							)}
						</div>

						<div className="p-field">
							<label htmlFor="owner">Owner</label>
							<InputText
								id="owner"
								value={this.state.station.owner}
								onChange={(e) => this.onInputChange(e, "owner")}
								required
								autoFocus
								className={`${
									this.state.submitted && !this.state.station.owner
										? "p-invalid"
										: ""
								}`}
							/>
							{this.state.submitted && !this.state.station.owner && (
								<small className="p-invalid">Owner is required.</small>
							)}
						</div>

						<div className="p-field">
							<label htmlFor="location">Location</label>
							<InputText
								id="location"
								value={this.state.station.location}
								onChange={(e) => this.onInputChange(e, "location")}
								required
								autoFocus
								className={`${
									this.state.submitted && !this.state.station.location
										? "p-invalid"
										: ""
								}`}
							/>
							{this.state.submitted && !this.state.station.location && (
								<small className="p-invalid">Location is required.</small>
							)}
						</div>

						<div className="p-field">
							<label className="p-mb-3">Status</label>
							<div className="p-formgrid p-grid">
								<div className="p-field-radiobutton p-col-6">
									<RadioButton
										id="category1"
										name="category"
										value="RELEASE"
										onChange={this.onCategoryChange}
										checked={this.state.station.status === "RELEASE"}
									/>
									<label
										htmlFor="category1"
										className="hl-label highlight-blue"
									>
										RELEASE
									</label>
								</div>
								<div className="p-field-radiobutton p-col-6">
									<RadioButton
										id="category2"
										name="category"
										value="TEST"
										onChange={this.onCategoryChange}
										checked={this.state.station.status === "TEST"}
									/>
									<label
										htmlFor="category2"
										className="hl-label highlight-yellow"
									>
										TEST
									</label>
								</div>
								<div className="p-field-radiobutton p-col-6">
									<RadioButton
										id="category3"
										name="category"
										value="INSTOCK"
										onChange={this.onCategoryChange}
										checked={this.state.station.status === "INSTOCK"}
									/>
									<label
										htmlFor="category3"
										className="hl-label highlight-green"
									>
										INSTOCK
									</label>
								</div>
								<div className="p-field-radiobutton p-col-6">
									<RadioButton
										id="category4"
										name="category"
										value="CUSTOM"
										onChange={this.onCategoryChange}
										checked={this.state.station.status === "CUSTOM"}
									/>
									<label
										htmlFor="category4"
										className="hl-label highlight-orange"
									>
										CUSTOM
									</label>
								</div>
								<div className="p-field-radiobutton p-col-6">
									<RadioButton
										id="category5"
										name="category"
										value="DAMAGED"
										onChange={this.onCategoryChange}
										checked={this.state.station.status === "DAMAGED"}
									/>
									<label htmlFor="category5" className="hl-label highlight-red">
										DAMAGED
									</label>
								</div>
								<div className="p-field-radiobutton p-col-6">
									<RadioButton
										id="category6"
										name="category"
										value="REFURBISHED"
										onChange={this.onCategoryChange}
										checked={this.state.station.status === "REFURBISHED"}
									/>
									<label
										htmlFor="category6"
										className="hl-label highlight-purple"
									>
										REFURBISHED
									</label>
								</div>
								<div className="p-field-radiobutton p-col-6">
									{this.state.submitted && !this.state.station.status && (
										<small className="p-invalid">Status is required.</small>
									)}
								</div>
							</div>
						</div>
					</Dialog>

					<Toolbar left={leftContents} right={rightContents} />

					<DataTable
						value={this.state.stationList}
						ref={(el) => {
							this.dt = el;
						}}
						selection={this.state.selectedList}
						onSelectionChange={(e) => this.setState({ selectedList: e.value })}
						// selectionMode="multiple"
						metaKeySelection={false}
						style={{ marginTop: "1em" }}
						className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive-demo"
						paginator
						paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
						currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
						rows={10}
						rowsPerPageOptions={[10, 20, 50, 100]}
						paginatorLeft={paginatorLeft}
						paginatorRight={paginatorRight}
						globalFilter={this.state.globalFilter}
						header={header}
					>
						<Column
							field="time"
							header="Created At"
							body={this.timeBodyTemplate}
							sortable
						></Column>
						<Column
							field="uuid"
							header="Station IMEI"
							body={this.uuidBodyTemplate}
							sortable
						></Column>
						<Column
							field="name"
							header="Station Name"
							body={this.nameBodyTemplate}
							sortable
						></Column>
						<Column
							field="status"
							header="Station Status"
							body={this.statusBodyTemplate}
							sortable
						></Column>
						<Column
							field="version"
							header="Version"
							body={this.versionBodyTemplate}
							sortable
						></Column>
						<Column
							field="owner"
							header="Owner"
							body={this.ownerBodyTemplate}
							sortable
						></Column>
						<Column
							header={<div align="center">Actions</div>}
							body={this.actionBodyTemplate}
						></Column>
					</DataTable>

					<Dialog
						visible={this.state.flag_DialogDelStation}
						style={{ width: "450px" }}
						header="Confirm"
						modal
						footer={footerDialogDelete}
						onHide={this.hideDialogDelStation}
					>
						<div className="confirmation-content">
							<i
								className="pi pi-exclamation-triangle p-mr-3"
								style={{ fontSize: "2rem" }}
							/>
							{this.state.station && (
								<span>
									Are you sure you want to delete{" "}
									<b>{this.state.station.name}</b>?
								</span>
							)}
						</div>
					</Dialog>
				</div>
			</div>
		);
	}
}
