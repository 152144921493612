import React, { Component } from "react";
import { Link } from "react-router-dom";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

import MD5 from "crypto-js/md5";
import jwt_decode from "jwt-decode";

import UserService from "../services/UserService";

import * as Constants from "../constants/constants";

export class AppPage404 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: "",
		};
	}

	render() {
		return (
			<div className="exception-body notfound">
				<div className="exception-content">
					<div className="moon">
						<img
							alt="mirage-layout"
							src={
								require("../assets/layout/images/pages/asset-moon.svg").default
							}
						/>
					</div>
					<div className="exception-panel">
						<div className="exception-panel-content">
							<span className="tag" style={{ fontSize: 60 }}>
								404
							</span>
							<h1>Page Not Found</h1>
							<div className="seperator"></div>
							<p>
								Requested resource is not available right now. Please try again
								later.
							</p>
							<Link to="/">
								<button
									label="Go To DashBoard"
									pbutton=""
									type="button"
									className="p-button p-component"
								>
									<span className="p-button-label">Go Back To DashBoard</span>
								</button>
							</Link>
						</div>
					</div>
					<div className="desert"></div>
				</div>
			</div>
		);
	}
}

export class AppPage401 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: "",
		};
	}

	render() {
		return (
			<div className="exception-body access">
				<div className="exception-content">
					<div className="moon">
						<img
							alt="mirage-layout"
							src={
								require("../assets/layout/images/pages/asset-moon.svg").default
							}
						/>
					</div>
					<div className="exception-panel">
						<div className="exception-panel-content">
							<span className="tag" style={{ fontSize: 60 }}>
								401
							</span>
							<h1>Access Denied</h1>
							<div className="seperator"></div>
							<p>
								You do not have the necesary permisions. Please contact admins.
							</p>
							<Link to="/">
								<button
									label="Go To DashBoard"
									pbutton=""
									type="button"
									className="p-button p-component"
								>
									<span className="p-button-label">Go Back To DashBoard</span>
								</button>
							</Link>
						</div>
					</div>
					<div className="desert"></div>
				</div>
			</div>
		);
	}
}

export class AppPageError extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: "",
		};
	}

	render() {
		return (
			<div className="exception-body error">
				<div className="exception-content">
					<div className="moon">
						<img
							alt="mirage-layout"
							src={
								require("../assets/layout/images/pages/asset-moon.svg").default
							}
						/>
					</div>
					<div className="exception-panel">
						<div className="exception-panel-content">
							<span className="tag" style={{ fontSize: 50 }}>
								<i
									className="pi pi-exclamation-triangle"
									style={{ fontSize: 60, verticalAlign: "middle" }}
								></i>
							</span>

							<h1>Error Occured</h1>
							<div className="seperator"></div>
							<p>Something went wrong.</p>
							<Link to="/">
								<button
									label="Go To DashBoard"
									pbutton=""
									type="button"
									className="p-button p-component"
								>
									<span className="p-button-label">Go Back To DashBoard</span>
								</button>
							</Link>
						</div>
					</div>
					<div className="desert"></div>
				</div>
			</div>
		);
	}
}

export class AppPageLogin extends Component {
	publicKey = "bcf8793917e605ed584c89b6241bb591";

	constructor(props) {
		super(props);
		this.state = {
			login: {
				username: "",
				password: "",
			},
			submitted: false,
		};

		this.userService = new UserService();

		this.onInputChange = this.onInputChange.bind(this);
		this.onLoginClick = this.onLoginClick.bind(this);

		this.handlerLogin = this.handlerLogin.bind(this);
	}

	onInputChange(e, name) {
		const val = (e.target && e.target.value) || "";
		let login = { ...this.state.login };
		login[`${name}`] = val;
		this.setState({ login });
	}

	onLoginClick() {
		if (this.state.login.username.length && this.state.login.password.length) {
			let loginPackage = {
				username: this.state.login.username,
				password: this.state.login.password,
				secret: MD5(
					`${this.state.login.username.toLowerCase()}${this.publicKey}${
						this.state.login.password
					}`
				).toString(),
			};
			this.handlerLogin(
				loginPackage.username,
				loginPackage.password,
				loginPackage.secret
			);
		}
		this.setState({ submitted: true });
	}

	async handlerLogin(username, password, secret) {
		try {
			let res = await this.userService.login(username, password, secret);
			let jwtDecoded = jwt_decode(res.data.jwt);
			localStorage.setItem(Constants.STORAGE_JWT, res.data.jwt);
			localStorage.setItem(Constants.STORAGE_JWT_EXPIRATION, jwtDecoded.exp);
			localStorage.setItem(Constants.STORAGE_USER_NAME, jwtDecoded.aud);
			localStorage.setItem(Constants.STORAGE_USER_AKA, jwtDecoded.aka);
			localStorage.setItem(Constants.STORAGE_USER_ROLE, jwtDecoded.role);
			window.location.href = "/";
		} catch (error) {
			console.log(error.response.data.message);
			this.toast.show({
				severity: "error",
				summary: "Error Message",
				detail: error.response.data.message,
				life: 5000,
			});
		}
	}

	render() {
		return (
			<div className="login-body layout-light">
				<div className="login-content">
					<Toast ref={(el) => (this.toast = el)} />
					<div className="login-panel">
						<div className="login-panel-content">
							<div className="logo">
								<img
									alt="mirage-layout"
									src={require("../assets/images/logo.png").default}
								/>
							</div>
							<h1>Sign in to vovan.cc</h1>
							<p>Welcome, please use the form to sign-in VoVan.CC network</p>
							<div className="forms">
								<div className="login-input-wrapper">
									<label htmlFor="username">Username</label>
									<InputText
										id="username"
										placeholder="Type your username..."
										value={this.state.login.username}
										onChange={(e) => this.onInputChange(e, "username")}
										required
										autoFocus
										className={`${
											this.state.submitted && !this.state.login.username
												? "p-invalid"
												: ""
										}`}
									/>
									<i className="pi pi-user"></i>
								</div>

								<div className="login-input-wrapper">
									<label htmlFor="password">Password</label>
									<InputText
										id="password"
										placeholder="Type your password..."
										type="password"
										value={this.state.login.password}
										onChange={(e) => this.onInputChange(e, "password")}
										required
										className={`${
											this.state.submitted && !this.state.login.password
												? "p-invalid"
												: ""
										}`}
									/>
									<i className="pi pi-lock"></i>
								</div>
								<div className="buttons">
									<Button
										label="Login"
										// icon="pi pi-arrow-right"
										className="p-button-raised p-button-primary"
										onClick={this.onLoginClick}
									/>
									<a className="secondary-button" href="/forget">
										Forgotten Password?
									</a>
								</div>
							</div>
						</div>
					</div>
					<div className="desert"></div>
				</div>
			</div>
		);
	}
}
