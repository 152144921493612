import React, { Component } from "react";

import { OrganizationChart } from "primereact/organizationchart";

export default class AppSitemap extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [
				{
					label: "HomePage",
					expanded: true,
					children: [
						{
							label: "Dashboard",
						},
						{
							label: "Sitemap",
						},
						{
							label: "Station",
							expanded: true,
							children: [
								{
									label: "Chart",
								},
								{
									label: "Table",
								},
								{
									label: "Map",
								},
							],
						},
						{
							label: "Device",
						},
						{
							label: "Customer",
						},
						{
							label: "Firmware",
						},
						{
							label: "Log",
						},
					],
				},
			],
		};
	}

	render() {
		return (
			<React.Fragment>
				<div className="p-grid p-fluid organizationchart-demo">
					<div className="p-col-12 p-lg-12">
						<div className="card">
							<OrganizationChart value={this.state.data}></OrganizationChart>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
